.game {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
}

.overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.76);
}

.game-stage {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 50px);
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.game-stage-album-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-current-album {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.game-stats {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-self: start;
  justify-content: space-between;
  padding: 0 20px;
}

.loading-state {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .game,
  .game-stage {
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .game-stage {
    justify-content: space-evenly;
    margin-top: 0;
  }
}
