.login-button {
  display: flex;
  width: 190px;
  height: 40px;
  align-items: center;
  padding-left: 8px;
  border: none;
  margin: 10px auto;
  background-color: #000;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.3s;
  user-select: none;
}

.login-button:hover {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.3);
}

.login-button-logo {
  height: 25px;
  margin-right: 10px;
  user-select: none;
}

@media (min-width: 1024px) {
  .login-button {
    width: 210px;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
  }

  .login-button span {
    width: 100%;
  }

  .login-button-logo {
    height: 30px;
    margin-right: 5px;
  }
}
