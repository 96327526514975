.album-artwork-container {
  overflow: hidden;
  width: 180px;
  height: 180px;
  flex-shrink: 0;
  border: 4px solid #1f2c3a;
  border: 4px solid white;
  margin-bottom: 10px;
  background-color: grey;
  box-shadow: 0 0 4px 0 #000;
}

@media (min-width: 375px) {
  .album-artwork-container {
    width: 220px;
    height: 220px;
    border-width: 6px;
  }
}

@media (min-width: 410px) {
  .album-artwork-container {
    width: 250px;
    height: 250px;
    border-width: 6px;
  }
}

@media (min-width: 1300px) and (min-height: 1024px) {
  .album-artwork-container {
    width: 280px;
    height: 280px;
    margin-bottom: 20px;
  }
}

.album-artwork {
  width: 100%;
  height: 100%;
  transition: filter 0.5s ease-in-out;
}
