.hangman-btn {
  width: 240px;
  height: 42px;
  padding: 8px 16px;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-family: ChicagoFLF, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 22px;
  text-decoration: none;
}

.hangman-btn[disabled] {
  border: none;
  background-image: none;
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text-wrapper {
  margin-left: 10px;
}

.hangman-btn-warning {
  background-color: #d94c4c;
}

.hangman-btn-warning:hover {
  background-color: #f05544;
}

.hangman-btn-success {
  background: #1abc56;
}

.hangman-btn-success:hover {
  background: #23d464;
}

.hangman-btn-primary {
  background: #496cb4;
}

.hangman-btn-primary:hover {
  background: #5879b9;
}

.hangman-btn-info {
  background: #38a1f3;
}

.hangman-btn-info:hover {
  background: rgb(77, 173, 247);
}
