.keyboard-container {
  position: relative;
  padding-top: 15px;
  border-top: 1px solid gainsboro;
  margin: 0 auto 20px;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-btn {
  min-width: 28px;
  height: 33px;
  flex: 1;
  padding: 8px 0;
  border: transparent;
  margin: 2px;
  background-color: #3d3d3d;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  outline: none;
}

@media (min-width: 360px) {
  .keyboard-btn {
    width: 29px;
    height: 34px;
  }
}

@media (min-width: 375px) {
  .keyboard-btn {
    min-width: 33px;
    height: 35px;
  }
}

@media (min-width: 410px) {
  .keyboard-btn {
    min-width: 37px;
    height: 40px;
    font-size: 15px;
  }
}

@media (min-width: 480px) {
  .keyboard-btn {
    min-width: 44px;
    height: 44px;
  }
}

@media (min-width: 768px) {
  .keyboard-btn {
    min-width: 53px;
    height: 50px;
    font-size: 19px;
  }
}

.keyboard-btn:hover {
  background-color: #585858;
  cursor: pointer;
}

.keyboard-btn-letter {
  display: block;
}

.keyboard-btn-fail {
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: red-change;
  font-weight: 600;
}

.keyboard-btn-success {
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: green-change;
  font-weight: 600;
}

.game-end-message {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2a2b30ed;
  border-radius: 5px;
  box-shadow: 0 0 20px #0000008a;
  color: #fff;
}

.game-status-msg {
  margin: 0 0 5px;
  font-family: ChicagoFLF, -apple-system, BlinkMacSystemFont, sans-serif;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .game-status-msg {
    margin: 20px 0;
  }
}

@keyframes red-change {
  0% {
    color: white;
  }

  40% {
    color: red;
  }

  50% {
    transform: scale(2, 2);
  }

  100% {
    color: red;
  }
}

@keyframes green-change {
  0% {
    color: white;
  }

  40% {
    color: greenyellow;
  }

  50% {
    transform: scale(2, 2);
  }

  100% {
    color: greenyellow;
  }
}
