.game-top-bar {
  position: sticky;
  z-index: 9;
  top: 0;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #ecf0f1c9;
}

.game-top-bar-settings-icon {
  cursor: pointer;
}

.game-top-bar-settings-icon svg {
  width: 30px;
  height: 30px;
}

.game-top-bar-settings-icon:hover .settings-icon-gear {
  stroke: rgb(207, 207, 207);
}

/* Change game top bar to use grid layout instead of flex, if the browser supports it.
   This will increase it's layout accuracy.
*/

@supports (display: grid) {
  .game-top-bar {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }

  .game-top-bar-settings-icon {
    justify-self: end;
  }

  .game-top-bar .progress-container {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .game-top-bar {
    height: 80px;
    padding: 0 25px;
  }

  .game-top-bar-settings-icon svg {
    width: 44px;
    height: 44px;
  }
}
