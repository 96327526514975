.landing {
  overflow: hidden;
}

.landing-header {
  padding: 0 20px;
  margin-bottom: 10px;
  color: #000;
  font-family: ChicagoFLF, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  font-size: 27px;
  font-weight: normal;
  transform: rotate(-3deg);
}

.landing-moving-albums {
  overflow: hidden;
  width: 200%;
  height: 180px;
  margin-bottom: 15px;
  background: #000;
  outline: 1px solid transparent;
  transform: translateX(-100px) rotate(-3deg);
}

.landing-moving-albums-image {
  height: 180px;
  animation: left-to-right 30s infinite;
  animation-fill-mode: backwards;
  animation-timing-function: linear;
  filter: blur(7px);
  user-select: none;
}

@media (prefers-reduced-motion: reduce) {
  .landing-moving-albums-image {
    animation: none;
  }
}

.landing-game-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-description {
  max-width: 400px;
  padding: 0 50px;
  margin-bottom: 10px;
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

.login-buttons {
  margin-bottom: 25px;
}

.landing-footer {
  width: 100%;
  max-width: 280px;
  margin: 0 auto 35px;
  color: #c0bfbf;
  font-weight: 300;
  text-align: center;
}

.landing-footer-created {
  display: block;
  margin-bottom: 2px;
}

.landing-footer-created-name {
  font-weight: 400;
}

.landing-footer-github-link {
  color: #c0bfbf;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.2s ease-out;
}

.landing-footer-github-link:hover {
  color: #8f8f8f;
}

/** For wide viewports **/

@media (min-width: 667px) {
  .landing-header {
    transform: rotate(-2deg);
  }

  .landing-moving-albums {
    margin-bottom: 50px;
    transform: translateX(-100px) rotate(-2deg);
  }

  .landing-description {
    max-width: 520px;
    padding: 0 90px;
    font-size: 18px;
  }
}

/** Tablets **/

@media (min-width: 768px) and (min-height: 690px) {
  .landing-header {
    font-size: 36px;
  }

  .landing-description {
    font-size: 19px;
  }

  .landing-moving-albums {
    margin-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .landing-header {
    margin-left: 20px;
    font-size: 46px;
  }
}

/** Animations **/

@keyframes left-to-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60.3%);
  }
}
