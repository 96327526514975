.hearts-container {
  display: flex;
  min-width: 70px;
  flex-direction: column;
}

.hearts-title {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 100;
}

.heart {
  width: 15px;
  height: 15px;
  margin: 0 1.25px;
  fill: #e00000;
  transition: fill 0.5s;
}

.heart-grey {
  fill: grey;
}

@media (min-width: 1024px) {
  .hearts-container {
    width: 130px;
  }

  .heart {
    width: 30px;
    height: 30px;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  animation: 500ms zoomOut ease-out;
}
