.hidden-album-name {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  font-family: Helvetica, sans-serif;
}

.hidden-word {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
}

.hidden-word-letter {
  display: flex;
  width: 35px;
  height: 45px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background-color: #4a5867;
  border-radius: 10px;
}

.hidden-word-letter span {
  font-size: 28px;
}

@media (min-width: 1025px) {
  .hidden-word-letter {
    width: 60px;
    height: 70px;
  }

  .hidden-word-letter span {
    font-size: 40px;
  }
}

@media (min-width: 1300px) {
  .hidden-album-name {
    max-width: 1260px;
  }

  .hidden-word-letter span {
    font-size: 46px;
  }
}
