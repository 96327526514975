html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-family: 'Helvetica Neue', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Ubuntu', sans-serif;
}

@font-face {
  font-family: 'ChicagoFLF';
  font-style: normal;
  font-weight: normal;
  src:
    local('Krungthep'),
    local('ChicagoFLF'),
    url('/fonts/ChicagoFLF.woff2') format('woff2'),
    url('/fonts/ChicagoFLF.woff') format('woff');
}
