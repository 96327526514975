.modal {
  position: fixed;
  z-index: 11;
  top: 90px;
  display: flex;
  width: 290px;
  height: 280px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgb(233, 233, 233);
  box-shadow: 0 0 0 7px rgb(31, 31, 31);
}
